import { Typography } from '@pitchero/react-ui';
import ClubLink from 'components/club-link';
import { Trans } from 'lib/i18n';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledLink = styled.a`
  text-decoration-line: ${(props) => (props.isActive ? 'underline' : 'none')};
  text-decoration-thickness: 4px;
  text-decoration-color: ${(props) => props.underlineColor};
  text-underline-offset: 10px;
  cursor: pointer;
  &:hover {
    text-decoration: underline 4px ${(props) => props.underlineColor};
  }
  padding: 10px;
  white-space: nowrap;
`;

const SecondaryNavigationLinks = ({ links, color, underlineColor, activeSection }) => {
  return (
    <>
      {links.map((link) => (
        <Typography preset="name" color={color} key={link.name}>
          <ClubLink route={link.route} params={link.params}>
            <StyledLink
              color={color}
              underlineColor={underlineColor}
              isActive={activeSection === link.name}
            >
              <Trans i18nKey={link.transKey}>{link.name}</Trans>
            </StyledLink>
          </ClubLink>
        </Typography>
      ))}
    </>
  );
};

SecondaryNavigationLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
      params: PropTypes.string.isRequired,
      transKey: PropTypes.string.isRequired,
    }),
  ).isRequired,
  color: PropTypes.string,
  underlineColor: PropTypes.string,
  activeSection: PropTypes.string.isRequired,
};

SecondaryNavigationLinks.defaultProps = {
  color: 'black',
  underlineColor: 'black',
};

export default SecondaryNavigationLinks;
